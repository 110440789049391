import React, { useState } from 'react';

const ExpandableSection = ({
    title,
    accentedText,
    expandedContent
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="relative">
            <div className="flex items-center justify-between">
                <div>
                    <p className="font-medium text-2xl">
                        {title}<br />
                        for <span className="text-accent">{accentedText}</span>
                    </p>
                </div>
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="flex items-center justify-center w-8 h-8 rounded-full border-[2px] border-auxiliary hover:bg-gray-400/20 transition-colors"
                    aria-label={isExpanded ? "Show less" : "Show more"}
                >
                    <span className="text-2xl text-auxiliary font-medium leading-none">
                        {isExpanded ? '−' : '+'}
                    </span>
                </button>
            </div>
            {isExpanded && (
                <div className="mt-6 text-lg text-secondary pr-16">
                    {expandedContent}
                </div>
            )}

        </div>
    );
};

export default ExpandableSection;