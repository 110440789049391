import App from 'App';
import React from 'react';
import MainContainer from 'containers/MainContainer';

const Index: React.FC = (props) => {
  return (
    <App className="" noGutter={true}>
      <MainContainer>
        {props.children}
      </MainContainer>
    </App>
  );
};
export default Index;
