import Button from 'common/components/Button';
import ExpandableSection from 'common/components/ExpandableSection';
import ValueInfoBox from 'common/components/ValueInfoBox';
import VerticalCard from 'common/components/VerticalCard';
import { advantagePoints, faqPoints, partners, REFRESH_INTERVALS } from 'common/constants/constants';
import { Link } from 'gatsby';
import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from 'react';
import { useQuery } from '@apollo/client';
import { QueryItemsResponse, QueryName } from 'common/models/graphql';
import { Dex } from 'common/models/dex';
import { listDexesQuery } from 'graphql/queries/listDexes.query';
import _ from 'lodash';
import Container from 'common/components/UI/Container';
import ScrollableImage from 'common/components/ScrollableImage';

const MainContainer: React.FC = () => {
  const {
    loading: loadingDexes,
    error,
    data: dataDexes
  } = useQuery<QueryItemsResponse<Dex>>(listDexesQuery, {
    fetchPolicy: 'cache-and-network',
    pollInterval: REFRESH_INTERVALS[QueryName.listDexes]
  });

  const dexes: Dex[] = dataDexes?.listDexes.items || [];
  console.log({dexes});

  const totalValue = _.sumBy(dexes, 'tvl');
  const totalVaults = _.sumBy(dexes, 'numVaults');
  const uniqueDexCount = _.uniqBy(dexes, 'dexName').length;
  const uniqueChainCount = _.uniqBy(dexes, 'chainId').length;

  return (
    <>
        <div className="flex flex-col items-center justify-center w-full ">
          <div className='main-container w-full'>
            <h1 className='px-3 mx-auto text-center pt-36'>Automated Liquidity Strategies<br />for DeFi Yield</h1>
            <div className="text-secondary text-xl mt-5 text-center max-w-[660px] mx-auto" >
              ICHI transforms liquidity management by turning your single-token deposits into optimized yields with reduced risk.
            </div>
          </div>
          
          <div className="flex flex-row justify-center md:justify-between flex-wrap my-4 gap-2">
            <OutboundLink target="_blank" href="https://docs.ichi.org/home/ichi-users" className="btn-link">
              <Button
                className="text secondary-button mb-3"
                title="Explore ICHI Solutions"
              />
            </OutboundLink>
            <Link target="_blank" to='/amms/' className="btn-link">
              <Button
                className="text primary-button  mb-3"
                title="Start Earning"
              />
            </Link>
          </div>
        </div>
      <Container marginBottom='60px'>
        <div className="flex flex-col items-center justify-center w-full ">
          <div className='flex flex-row flex-wrap gap-4 justify-between w-full my-20'>
            <ValueInfoBox 
              title='total value locked'
              value={totalValue}
              prefix='$'
              displayDecimals={2}
            />
            <ValueInfoBox 
              title='Supported AMMs'
              value={uniqueDexCount}
              displayDecimals={0}
            />
            <ValueInfoBox 
              title='Supported Networks'
              value={uniqueChainCount}
              displayDecimals={0}
            />
            <ValueInfoBox 
              title='Available Vaults'
              value={totalVaults}
              displayDecimals={0}
            />
          </div>
          <div className='relative flex flex-row flex-wrap w-full my-10'>
            <div className='lg:sticky top-24 h-fit sm:w-[calc(100%-1rem)] md:w-[calc(100%-1rem)] lg:w-[calc(50%-1rem)]'>
              <div className='flex flex-col md:flex-row lg:flex-col mb-5'>
                <h1 className='leading-none pb-8  max-w-[560px]'><span className='text-accent'>Powering</span> growth across crypto</h1>
                <OutboundLink target='blank' href='https://docs.ichi.org/home/case-studies' className='flex flex-row text-secondary gap-1 w-full md:justify-end lg:justify-start'>
                  <span className='underline underline-offset-4'>Read Case Studies</span>
                  <img src="../images/arrow.png" className='w-5 h-5' />
                </OutboundLink> 
              </div>
            </div>
            <div className='w-full  sm:w-[calc(100%-1rem)] md:w-[calc(100%-1rem)] lg:w-[calc(50%-1rem)] flex flex-col justify-between'>
              {advantagePoints.map((aPoint) => (
                <>
                  <ExpandableSection 
                    title={aPoint.title}
                    accentedText={aPoint.accentedText}
                    expandedContent={aPoint.content}
                  />
                  <hr/>
                </>
              ))}
            </div>
          </div>
          <div className='w-full my-12 area-accent'>
            <h3 className='text-white'>Innovating alongside<br/>industry leaders</h3>
          </div>
          <div className='my-12 text-center pb-5'>
            <img className='partner-logos hidden md:block'/>
          </div>
        </div>
        <ScrollableImage className='partner-logos md:hidden -left-[50%]' alt='Industry partners and leaders' />

        <div className='flex flex-row flex-wrap w-full mt-4 gap-5'>
          <div className='w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] lg:w-[calc(50%-1rem)] area-gradient'>
            <h3 className='text-left text-inverted pt-16 pb-16 px-10'><span className='text-accent'>Security you can trust,</span><br/>
                verified by the best.
            </h3>
          </div>
          <div className='w-full  sm:w-[calc(100%-1rem)] md:w-[calc(50%-1rem)] lg:w-[calc(50%-1rem)] p-10 pt-12 bg-surface-soft rounded-2xl'>
            <div className='w-full flex flex-row flex-wrap justify-center pt-6 items-center gap-10 lg:gap-0'>
              {partners.map((partner) => (
                <VerticalCard
                  key={partner.name}
                  title={partner.name}
                  url={partner.url}
                  imageFileName={`${partner.logo}`} />
              ))}
            </div>
          </div>
        </div>

      </Container>
    </>
  );
};

export default MainContainer;
