import React, { useEffect, useRef } from 'react';

const ScrollableImage = ({ alt, className }) => {
    const scrollContainerRef = useRef(null);

    useEffect(() => {
      // Center the scroll position when component mounts
      if (scrollContainerRef.current) {
        const container = scrollContainerRef.current;
        const scrollWidth = container.scrollWidth;
        const clientWidth = container.clientWidth;
        const centerPosition = (scrollWidth - clientWidth) / 2;
        container.scrollLeft = centerPosition;
      }
    }, []);
    
    return (
        <div className="relative w-full">
        {/* Desktop view - normal display */}
        <div className="hidden md:block">
            <img 
            alt={alt} 
            className={`w-full h-auto ${className || ''}`}
            />
        </div>
        
        {/* Mobile view - scrollable container */}
        <div className="md:hidden relative">
            <div 
                ref={scrollContainerRef}
                className="overflow-x-auto pb-4"
            >
                <div className="min-w-[1000px]">
                    <img 
                    alt={alt} 
                    className={`w-full h-auto ${className || ''}`}
                    />
                </div>
            </div>
            
            {/* Optional scroll indicator */}
            {/* <div className="absolute bottom-0 left-0 right-0 h-1 bg-gray-200">
            <div className="w-1/3 h-full bg-blue-500 rounded"></div>
            </div> */}
        </div>
        </div>
    );
};

export default ScrollableImage;