import React from 'react';
import FormatNumber from 'utils/FormatNumber';

type Props = {
  title: string;
  value: number;
  prefix?: string;
  displayDecimals: number;
}

const ValueInfoBox: React.FC<Props> = (props) => {
  return (
    <div className='flex flex-col justify-between p-5 bg-surface-soft w-full w-[calc(50%-1rem)] lg:w-[calc(25%-1rem)] rounded-2xl gap-5'>
      <div className='text-secondary text-sm font-bold uppercase'>{props.title}</div>
      <div className='text-neutral text-[40px] font-medium'>
        <FormatNumber value={props.value} prefix={props.prefix} displayDecimals={props.displayDecimals} abbreviate />
      </div>
    </div>
  )
};

export default ValueInfoBox;

