import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';

type Props = {
  title: string;
  imageFileName: string;
  url?: string;
}

const VerticalCard: React.FC<Props> = (props) => {
  const outterClassName = "flex flex-col items-center p-0 mb-6  lg:w-[calc(33.333%-1rem)]";

  const innerCard = (
    <div className={`flex flex-col w-full h-full items-center h-[72px] justify-between`}>
      <div className=" flex flex-row justify-start items-center mb-4">
        <img 
          alt={props.title} 
          className={`${props.imageFileName} pb-0 mb-0 h-10`} 
        />
      </div>
      <div className="text-secondary text-center text-sm font-medium uppercase">{props.title}</div>
    </div>
  )

  return (
    <>
      {props.url ? (
        <div className={outterClassName}>
          <OutboundLink href={props.url} target="blank">
            {innerCard}
          </OutboundLink>
        </div>
      ) : (
        <div className={outterClassName}>
          {innerCard}
        </div>
      )}
    </>
  )
};

export default VerticalCard;

